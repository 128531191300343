import { createGlobalStyle, css } from "styled-components";
import {
  dropdownItemHoverActiveStyles,
  dropdownItemStyles,
} from "./Theme/common";

import rem from "../utils/rem";

import { BREAKPOINTS } from "../common/data/constants";

function createSpaces(
  important: boolean,
  breakpoint?: "sm" | "md" | "lg" | "xl" | "xxl",
  breakpointDirection?: "above" | "under"
) {
  let styles = "";

  for (let i = 0; i <= 30; i++) {
    if (i === 0) {
      styles += `
            .mt${
              breakpoint ? `-${breakpointDirection}-${breakpoint}-` : "-"
            }auto${important ? "--i" : ""} {
                margin-top: auto${important ? " !important" : ""};
            }

            .mr${
              breakpoint ? `-${breakpointDirection}-${breakpoint}-` : "-"
            }auto${important ? "--i" : ""} {
                margin-right: auto${important ? " !important" : ""};
            }

            .mb${
              breakpoint ? `-${breakpointDirection}-${breakpoint}-` : "-"
            }auto${important ? "--i" : ""} {
                margin-bottom: auto${important ? " !important" : ""};
            }

            .ml${
              breakpoint ? `-${breakpointDirection}-${breakpoint}-` : "-"
            }auto${important ? "--i" : ""} {
                margin-left: auto${important ? " !important" : ""};
            }
            `;
    }

    styles += `
        .pt${breakpoint ? `-${breakpointDirection}-${breakpoint}-` : "-"}${i}${
      important ? "--i" : ""
    } {
            padding-top: ${i}px ${important ? " !important" : ""};
        }

        .pr${breakpoint ? `-${breakpointDirection}-${breakpoint}-` : "-"}${i}${
      important ? "--i" : ""
    } {
            padding-right: ${i}px ${important ? " !important" : ""};
        }

        .pb${breakpoint ? `-${breakpointDirection}-${breakpoint}-` : "-"}${i}${
      important ? "--i" : ""
    } {
            padding-bottom: ${i}px ${important ? " !important" : ""};
        }

        .pl${breakpoint ? `-${breakpointDirection}-${breakpoint}-` : "-"}${i}${
      important ? "--i" : ""
    } {
            padding-left: ${i}px ${important ? " !important" : ""};
        }
        

        .mt${breakpoint ? `-${breakpointDirection}-${breakpoint}-` : "-"}${i}${
      important ? "--i" : ""
    } {
            margin-top: ${i}px ${important ? " !important" : ""};
        }

        .mr${breakpoint ? `-${breakpointDirection}-${breakpoint}-` : "-"}${i}${
      important ? "--i" : ""
    } {
            margin-right: ${i}px ${important ? " !important" : ""};
        }

        .mb${breakpoint ? `-${breakpointDirection}-${breakpoint}-` : "-"}${i}${
      important ? "--i" : ""
    } {
            margin-bottom: ${i}px ${important ? " !important" : ""};
        }

        .ml${breakpoint ? `-${breakpointDirection}-${breakpoint}-` : "-"}${i}${
      important ? "--i" : ""
    } {
            margin-left: ${i}px ${important ? " !important" : ""};
        }
        `;
  }

  return css`
    ${styles}
  `;
}

export default createGlobalStyle`
    :root {
        --toastify-toast-min-height: auto;
    }

    html, body {
        margin: 0;
        padding: 0;
        outline: 0;
    }

    /* Rem on body because user can set different root (html) value in preferences */
    body {
        color: ${({ theme }) => theme.colors.grey[900] || "#0A121A"};
        font-family: ${({ theme }) => theme.typography.font}, sans-serif;
        font-size: ${rem(16)};
        line-height: 1.4;
    }

    * {
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
        scroll-behavior: smooth;
    }

    a {
        color: ${({ theme }) => theme.colors.grey[800] || "#273038"};
        text-decoration: none;
        font-size: ${rem(14)};
        transition: color .2s ease;

        &:hover,
        &:active,
        &:focus {
            color: ${({ theme }) => theme.colors.grey[900] || "#0A121A"};
        }
    }

    h1, h2, h3, h4, h5, h6 {
        font-weight: 500;
        line-height: 1.33;

        > svg {
            margin-right: 7px;
            flex-shrink: 0;
        }
    }

    h1 {
        font-size: ${({ theme }) => theme.typography.h1};
    }

    h2 {
        font-size: ${({ theme }) => theme.typography.h2};
    }

    h3 {
        font-size: ${({ theme }) => theme.typography.h3};
    }

    h4 {
        font-size: ${({ theme }) => theme.typography.h4};
    }

    h5 {
        font-size: ${({ theme }) => theme.typography.h5};
    }

    h6 {
        font-size: ${({ theme }) => theme.typography.h6};
    }

    strong {
        font-weight: ${({ theme }) => theme.typography.bold};
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    
    ol, ul {
        list-style-position: inside;
    }

    hr {
        border-color: ${({ theme }) => theme.colors.grey[300]};
    }

    code {
        font-family: monospace;
        background-color: ${({ theme }) => theme.colors.grey[300]};
        padding: 3px;
    }

    pre {
        color: #24292e;
        background-color: ${({ theme }) => theme.colors.grey[300]};
        font-family: "ui-monospace", "SFMono-Regular", "SF Mono", Menlo, Consolas,
            "Liberation Mono", monospace;
        padding: 3px;

        code {
            color: inherit;
            padding: 0;
            background: none;
            font-size: 0.8rem;
        }
    }

    small {
        font-size: 80%;
    }

    blockquote {
        border-left: 2px solid #dddddd;
        margin-left: 0;
        margin-right: 0;
        padding-left: 10px;
        color: #aaaaaa;
        font-style: italic;
    }

    .list-unstyled {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    .hide-above {
        &-sm {
            ${({ theme }) => theme.breakpoints.up("sm")} {
                display: none !important;
            }
        }

        &-md {
            ${({ theme }) => theme.breakpoints.up("md")} {
                display: none !important;
            }
        }

        &-lg {
            ${({ theme }) => theme.breakpoints.up("lg")} {
                display: none !important;
            }
        }

        &-xl {
            ${({ theme }) => theme.breakpoints.up("xl")} {
                display: none !important;
            }
        }

        &-xxl {
            ${({ theme }) => theme.breakpoints.up("xxl")} {
                display: none !important;
            }
        }
    }

    .hide-under {
        &-xxl {
            ${({ theme }) => theme.breakpoints.down("xxl")} {
                display: none !important;
            }
        }

        &-xl {
            ${({ theme }) => theme.breakpoints.down("xl")} {
                display: none !important;
            }
        }

        &-lg {
            ${({ theme }) => theme.breakpoints.down("lg")} {
                display: none !important;
            }
        }

        &-md {
            ${({ theme }) => theme.breakpoints.down("md")} {
                display: none !important;
            }
        }

        &-sm {
            ${({ theme }) => theme.breakpoints.down("sm")} {
                display: none !important;
            }
        }
    }

    .display-none {
        display: none;

        &--i {
            display: none !important;
        }
    }

    .display-block {
        display: block;

        &--i {
            display: block !important;
        }
    }

    .display-flex {
        display: flex;

        &--i {
            display: flex !important;
        }

        &--expand {
            flex: 1;

            &--i {
                flex: 1 !important;
            }
        }

        &--a-start {
            align-items: start;

            &--i {
                align-items: start !important;
            }
        }

        &--a-center {
            align-items: center;

            &--i {
                align-items: center !important;
            }
        }

        &--a-end {
            align-items: end;

            &--i {
                align-items: end !important;
            }
        }

        &--j-start {
            justify-content: flex-start;

            &--i {
                justify-content: flex-start !important;
            }
        }

        &--j-center {
            justify-content: center;

            &--i {
                justify-content: center !important;
            }
        }

        &--j-between {
            justify-content: space-between;

            &--i {
                justify-content: space-between !important;
            }
        }

        &--j-end {
            justify-content: flex-end;

            &--i {
                justify-content: flex-end !important;
            }
        }

        &--row {
            flex-direction: row;

            &--i {
                flex-direction: row !important;
            }
        }

        &--column {
            flex-direction: column;

            &--i {
                flex-direction: column !important;
            }
        }

        &--wrap {
            flex-wrap: wrap;

            &--i {
                flex-wrap: wrap !important;
            }
        }

        &--stop-auto-width {
            min-width: 0;

            &--i {
                min-width: 0 !important;
            }
        }

        &--stop-auto-height {
            min-height: 0;

            &--i {
                min-height: 0 !important;
            }
        }

        &--stop-shrink {
            flex-shrink: 0;

            &--i {
                flex-shrink: 0 !important;
            }
        }
    }

    .overflow {
        &-hidden {
            overflow: hidden;
        }

        &-auto {
            overflow: auto;
        }
    }

    .overflow-wrap {
        &--anywhere {
            overflow-wrap: anywhere;
        }
    }

    .max-width {
        &--100 {
            max-width: 100%;
        }
    }

    .pointer {
        cursor: pointer
    }

    .box-shadow {
        box-shadow: ${({ theme }) => theme.boxShadow};

        &-secondary {
            box-shadow: ${({ theme }) => theme.boxShadowSecondary};
        }

        &-dropdown {
            box-shadow: ${({ theme }) => theme.boxShadowDropdown};
        }
    }

    .sticky-top {
        position: sticky;
        top: 0;
        left: 0%;
        right: 0;
        z-index: ${({ theme }) => theme.zIndex.stickyTop};
    }

    .sticky-bottom {
        position: sticky;
        bottom: 0;
        left: 0%;
        right: 0;
        z-index: ${({ theme }) => theme.zIndex.stickyFooter};
    }

    .caret-animation {
        transition: transform .3s ease;

        &.is-open {
            transform: rotate(-180deg);
        }
    }

    .rotate-animation {
        animation: rotate 1.2s linear infinite;

        @keyframes rotate {
            0% {
                transform: rotate(0deg);
            }
            
            100% {
                transform: rotate(360deg);
            }
        }
    }

    .Toastify {
        &__close-button {
            align-self: center;
        }
        
        &__toast {
            border-left: 3px solid #000000;

            &--success {
                border-color: ${({ theme }) => theme.colors.success.base};
            }
        }
    }

    .contexify {
        background-color: #fff;
        border-radius: 8px;
        border: 1px solid ${({ theme }) => theme.colors.grey[300]};
        box-shadow: ${({ theme }) => theme.boxShadow};
        z-index: ${({ theme }) => theme.zIndex.popouts};
        padding: 0;
        min-width: 200px;

        &_separator {
            background-color: ${({ theme }) => theme.colors.grey[300]};
        }
        
        &_item {
            padding-left: 8px;
            padding-right: 8px;

            &:first-child {
                padding-top: 8px;
            }

            &:last-child {
                padding-bottom: 8px;
            }

            &Content {
                ${dropdownItemStyles};
            }

            &:not(.contexify_item-disabled) {
                &:hover,
                &:active,
                &:focus {
                    > .contexify_itemContent {
                        ${dropdownItemHoverActiveStyles};
                        color: #000000;
                    }
                }
            }
        }

        &_submenu {
            &--is-open {
                background-color: transparent;
                color: #000000;

                > .react-contexify__item__content {
                    ${dropdownItemHoverActiveStyles};
                    color: #000000;
                }
            }
        }
    }

    .fc-event--no-style {
        background-color: transparent !important;
        border: none !important;
        box-shadow: none !important;

        &:hover,
        &:active,
        &:focus {
            background-color: transparent !important;
        }
    }

    .tooltip-cmp {
        box-shadow: ${({ theme }) => theme.boxShadow};
        max-width: 250px;
        font-weight: normal;
        padding: 10px !important;
        overflow-wrap: anywhere;
    }

    .has-border {
        border: 1px solid ${({ theme }) => theme.colors.grey[300]};

        &-top {
            border-top: 1px solid ${({ theme }) => theme.colors.grey[300]};
        }

        &-right {
            border-right: 1px solid ${({ theme }) => theme.colors.grey[300]};
        }

        &-bottom {
            border-bottom: 1px solid ${({ theme }) => theme.colors.grey[300]};
        }

        &-left {
            border-left: 1px solid ${({ theme }) => theme.colors.grey[300]};
        }
    }

    .has-border-radius {
        border-radius: ${({ theme }) => theme.borderRadius};

        &-md {
            border-radius: ${({ theme }) => theme.borderRadiusMd};
        }

        &-lg {
            border-radius: ${({ theme }) => theme.borderRadiusLg};
        }

        &-rounded {
            border-radius: 50%;
        }
    }

    .has-border-radius-top {
        border-top-left-radius: ${({ theme }) => theme.borderRadius};
        border-top-right-radius: ${({ theme }) => theme.borderRadius};

        &-md {
            border-top-left-radius: ${({ theme }) => theme.borderRadiusMd};
            border-top-right-radius: ${({ theme }) => theme.borderRadiusMd};
        }

        &-lg {
            border-top-left-radius: ${({ theme }) => theme.borderRadiusLg};
            border-top-right-radius: ${({ theme }) => theme.borderRadiusLg};
        }
    }

    .has-border-radius-bottom {
        border-bottom-left-radius: ${({ theme }) => theme.borderRadius};
        border-bottom-right-radius: ${({ theme }) => theme.borderRadius};

        &-md {
            border-bottom-left-radius: ${({ theme }) => theme.borderRadiusMd};
            border-bottom-right-radius: ${({ theme }) => theme.borderRadiusMd};
        }

        &-lg {
            border-bottom-left-radius: ${({ theme }) => theme.borderRadiusLg};
            border-bottom-right-radius: ${({ theme }) => theme.borderRadiusLg};
        }
    }

    .text {
        &-light {
            font-weight: 300;
        }

        &-regular {
            font-weight: 400;
        }

        &-medium {
            font-weight: 500;
        }

        &-bold {
            font-weight: 600;
        }

        &-uppercase {
            text-transform: uppercase;
        }

        &-lowercase {
            text-transform: lowercase;
        }

        &-capitalize {
            text-transform: capitalize;
        }

        &-underline {
            text-decoration: underline;
        }

        &-center {
            text-align: center;
        }

        &-right {
            text-align: right;
        }

        &-line-1 {
            line-height: 1;
        }

        &-8 {
            font-size: ${rem(8)};
        }

        &-9 {
            font-size: ${rem(9)};
        }

        &-10 {
            font-size: ${rem(10)};
        }

        &-11 {
            font-size: ${rem(11)};
        }

        &-12 {
            font-size: ${rem(12)};
        }

        &-13 {
            font-size: ${rem(13)};
        }

        &-14 {
            font-size: ${rem(14)};
        }

        &-16 {
            font-size: ${rem(16)};
        }

        &-18 {
            font-size: ${rem(18)};
        }

        &-20 {
            font-size: ${rem(20)};
        }

        &-22 {
            font-size: ${rem(22)};
        }

        &-24 {
            font-size: ${rem(24)};
        }
    }

    .white-space {
        &-normal {
            white-space: normal;
        }

        &-no-wrap {
            white-space: nowrap;
        }
    }

    .truncate-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &-2 {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* number of lines to show */
                    line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        &-3 {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3; /* number of lines to show */
                    line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }

    .color {
        &-primary {
            color: ${({ theme }) => theme.colors.primary.base} !important;
        }

        &-secondary {
            color: ${({ theme }) => theme.colors.secondary.base} !important;
        }

        &-accent {
            color: ${({ theme }) => theme.colors.accent.base} !important;
        }

        &-grey {
            color: ${({ theme }) => theme.colors.grey.base} !important;
        }

        &-grey-300 {
            color: ${({ theme }) => theme.colors.grey[300]} !important;
        }

        &-grey-400 {
            color: ${({ theme }) => theme.colors.grey[400]} !important;
        }

        &-grey-500 {
            color: ${({ theme }) => theme.colors.grey[500]} !important;
        }

        &-grey-600 {
            color: ${({ theme }) => theme.colors.grey[600]} !important;
        }

        &-grey-650 {
            color: ${({ theme }) => theme.colors.grey[650]} !important;
        }

        &-grey-800 {
            color: ${({ theme }) => theme.colors.grey[800]} !important;
        }

        &-grey-900 {
            color: ${({ theme }) => theme.colors.grey[900]} !important;
        }

        &-blue {
            color: ${({ theme }) => theme.colors.blue.base} !important;
        }

        &-orange {
            color: ${({ theme }) => theme.colors.orange.base} !important;
        }

        &-success {
            color: ${({ theme }) => theme.colors.success.base} !important;
        }

        &-warning {
            color: ${({ theme }) => theme.colors.warning.base} !important;
        }

        &-black {
            color: #000000 !important;
        }

        &-white {
            color: #ffffff !important;
        }
    }

    .bg-color {
        &-primary {
            background-color: ${({ theme }) => theme.colors.primary.base};
        }

        &-secondary {
            background-color: ${({ theme }) => theme.colors.secondary.base};
        }

        &-accent {
            background-color: ${({ theme }) => theme.colors.accent.base};
        }

        &-grey {
            background-color: ${({ theme }) => theme.colors.grey.base};
        }

        &-grey-50 {
            background-color: ${({ theme }) => theme.colors.grey[50]};
        }

        &-grey-100 {
            background-color: ${({ theme }) => theme.colors.grey[100]};
        }

        &-grey-200 {
            background-color: ${({ theme }) => theme.colors.grey[200]};
        }

        &-grey-300 {
            background-color: ${({ theme }) => theme.colors.grey[300]};
        }

        &-grey-400 {
            background-color: ${({ theme }) => theme.colors.grey[400]};
        }

        &-grey-500 {
            background-color: ${({ theme }) => theme.colors.grey[500]};
        }

        &-grey-600 {
            background-color: ${({ theme }) => theme.colors.grey[600]};
        }

        &-grey-650 {
            background-color: ${({ theme }) => theme.colors.grey[650]};
        }

        &-grey-800 {
            background-color: ${({ theme }) => theme.colors.grey[800]};
        }

        &-grey-900 {
            background-color: ${({ theme }) => theme.colors.grey[900]};
        }

        &-blue {
            background-color: ${({ theme }) => theme.colors.blue.base};
        }

        &-orange {
            background-color: ${({ theme }) => theme.colors.orange.base};
        }

        &-success {
            background-color: ${({ theme }) => theme.colors.success.base};
        }

        &-warning {
            background-color: ${({ theme }) => theme.colors.warning.base};
        }

        &-black {
            background-color: #000000;
        }

        &-white {
            background-color: #ffffff;
        }
    }

    ${createSpaces(false)};

    ${createSpaces(true)};
    
    @media screen and (min-width: ${BREAKPOINTS["sm"]}) {
        ${createSpaces(false, "sm", "above")};
        ${createSpaces(true, "sm", "above")};
    }

    @media screen and (min-width: ${BREAKPOINTS["md"]}) {
        ${createSpaces(false, "md", "above")};
        ${createSpaces(true, "md", "above")};
    }

    @media screen and (min-width: ${BREAKPOINTS["lg"]}) {
        ${createSpaces(false, "lg", "above")};
        ${createSpaces(true, "lg", "above")};
    }

    @media screen and (min-width: ${BREAKPOINTS["xl"]}) {
        ${createSpaces(false, "xl", "above")};
        ${createSpaces(true, "xl", "above")};
    }

    @media screen and (min-width: ${BREAKPOINTS["xxl"]}) {
        ${createSpaces(false, "xxl", "above")};
        ${createSpaces(true, "xxl", "above")};
    }

    
    @media screen and (max-width: calc(${BREAKPOINTS["xxl"]} - 1px)) {
        ${createSpaces(false, "xxl", "under")};
        ${createSpaces(true, "xxl", "under")};
    }

    @media screen and (max-width: calc(${BREAKPOINTS["xl"]} - 1px)) {
        ${createSpaces(false, "xl", "under")};
        ${createSpaces(true, "xl", "under")};
    }

    @media screen and (max-width: calc(${BREAKPOINTS["lg"]} - 1px)) {
        ${createSpaces(false, "lg", "under")};
        ${createSpaces(true, "lg", "under")};
    }

    @media screen and (max-width: calc(${BREAKPOINTS["md"]} - 1px)) {
        ${createSpaces(false, "md", "under")};
        ${createSpaces(true, "md", "under")};
    }

    @media screen and (max-width: calc(${BREAKPOINTS["sm"]} - 1px)) {
        ${createSpaces(false, "sm", "under")};
        ${createSpaces(true, "sm", "under")};
    }

    .stop-body-scroll {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
    }

    .tiptap-content {
        font-size: ${rem(14)};
        word-break: break-word;

        &:focus-visible {
            outline: none;
        }

        .ProseMirror > * + *,
        > * + * {
            margin-top: 0.75em;
        }

        .ProseMirror > *:first-child,
        > *:first-child {
            margin-top: 0;
        }

        .ProseMirror > *:last-child,
        > *:last-child {
            margin-bottom: 0;
        }

        ul {
            list-style-type: circle;
        }

        ul,
        ol {
            margin: 0.8em 0;
            padding: 0 0 0 2em;

            li {
                > ul,
                > ol {
                    margin: 0;
                }

                p {
                    display: inline;
                    margin: 0;
                }
            }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            line-height: 1.1;
        }

        p {
            margin: 0.8em 0;
        }

        a {
            text-decoration: underline;
        }

        hr {
            border: none;
            border-top: 2px solid ${({ theme }) => theme.colors.grey[300]};
            margin: 1.5rem 0;
        }

        p.is-editor-empty:first-child {
            &::before {
                content: attr(data-placeholder);
                float: left;
                color: ${({ theme }) => theme.colors.grey[600]};
                pointer-events: none;
                height: 0;
            }
        }

        .tip-image {
            max-width: 100%;
            max-height: 200px;
        }
    }


    @media print {
        .hidden-print {
            display: none !important;
        }

        .page-break-before {
            break-before: page;
        }

        .page-break-after {
            break-after: page;
        }
    }
`;
